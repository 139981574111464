<template>
  <div>
    <div v-if="Question">
      <div v-if="!preview_evaluation || evaluator_view">
        <b-form-input
          v-if="allows_crud"
          class="mt-0 input-number"
          :class="{
            'score-input-warning':
              maxScore > Question.max_score &&
              matching_question_score.score > 0,
          }"
          v-b-tooltip.top.noninteractive.v-danger="
            `${
              maxScore > Question.max_score && matching_question_score.score > 0
                ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'
                : ''
            }`
          "
          v-model="matching_question_score.score"
          @input="PatchScore(matching_question_score)"
          type="number"
          size="sm"
        ></b-form-input>
        <template v-else>
          <div class="border-div ml-2 mr-3 pl-1 pr-1 noprint">
            {{ matching_question_score.score }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatchingQuestionScoreInput",
  components: {},
  props: {
    matching_question_left: { type: Number, required: true },
    matching_question_top: { type: Number, required: true },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    Question: { type: Object, required: true },
    matchingQuestionScore: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          matching_question_unit_1: this.matching_question_left,
          matching_question_unit_2: this.matching_question_top,
          score: 0,
        };
      },
    },
    preview_evaluation: { type: Boolean, required: false },
    allows_crud: { type: Boolean, required: false },
  },
  data() {
    return {
      matching_question_score: { ...this.matchingQuestionScore },
    };
  },
  computed: {
    ...mapGetters({
      matching_question_options: names.NEW_MATCHING_QUESTION_OPTIONS,
      matching_question_scores: names.NEW_MATCHING_QUESTION_SCORES,
    }),
    matchingQuestionOptions() {
      return this.matching_question_options.filter(
        (x) => x.matching_question == this.Question.id
      );
    },
    maxScore() {
      let score = 0;
      const maching_question_options = this.matchingQuestionOptions.filter(
        (x) => x.display_to_the_left == true
      );
      maching_question_options.forEach((option) => {
        let list = this.matching_question_scores
          .filter((x) => x.matching_question_unit_1 == option.id)
          .sort((a, b) => b.score - a.score);
        for (let index = 0; index < list.length; index++) {
          if (option.how_many_selected >= index + 1 && list[index].score > 0) {
            score += list[index].score;
          }
        }
      });
      return score;
    },
  },
  methods: {
    PatchScore(option) {
      if (option.score != null && option.score != "") {
        this.debouncePatch();
      }
    },
    debouncePatch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        if (
          this.matching_question_score.score != null &&
          this.matching_question_score.score != ""
        ) {
          if (
            isNaN(this.matching_question_score.id) &&
            this.matching_question_score.matching_question_unit_1 != null &&
            this.matching_question_score.matching_question_unit_2 != null
          )
            this.createdMatchingQuestionScore();
          else if (!isNaN(this.matching_question_score.id))
            this.patchMatchingQuestionScore();
        }
      }, 1000);
    },
    createdMatchingQuestionScore() {
      this.$store
        .dispatch(
          names.POST_NEW_MATCHING_QUESTION_SCORE,
          this.matching_question_score
        )
        .then(() => {
          toast("Puntaje actualizado.");
        });
    },
    patchMatchingQuestionScore() {
      this.$store
        .dispatch(names.PATCH_NEW_MATCHING_QUESTION_SCORE, {
          new_matching_question_score_id: this.matching_question_score.id,
          item: {
            score: this.matching_question_score.score,
          },
        })
        .then(() => {
          toast("Puntaje actualizado.");
        });
    },
  },
  created() {
    this.$store
      .dispatch(names.FETCH_NEW_MATCHING_QUESTION_SCORES_BY, {
        matching_question_unit_1: this.matching_question_left,
        matching_question_unit_2: this.matching_question_top,
      })
      .then((response) => {
        if (response) {
          this.matching_question_score = response;
        }
      });
  },
};
</script>

<style scoped>
.border-div {
  min-width: 50px;
  border: 1px solid #c9c9c9;
  min-height: 25px;
  border-radius: 3px;
}
.input-number {
  --bottom-border-color: var(--kl-menu-color);
  max-width: 100%;
  border: none;
  border: 1px solid #c9c9c9;
  overflow: hidden;
  border-radius: 3px;
  text-align: center;
}
.input-number:focus {
  outline: none !important;
  border-bottom: 2px solid var(--bottom-border-color);
}
.score-input-warning {
  border: 1px solid #dc3545 !important;
  color: red;
}
@media (max-width: 750px) {
  .border-div {
    margin-right: 10px !important;
  }
}
@media print {
  .border-div {
    margin-right: 1rem;
    border: none;
  }
}
</style>

