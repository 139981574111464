var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.Question)?_c('div',[(!_vm.preview_evaluation || _vm.evaluator_view)?_c('div',[(_vm.allows_crud)?_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-danger",value:(
          `${
            _vm.maxScore > _vm.Question.max_score && _vm.matching_question_score.score > 0
              ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'
              : ''
          }`
        ),expression:"\n          `${\n            maxScore > Question.max_score && matching_question_score.score > 0\n              ? 'El puntaje total de las opciones es mayor que el puntaje total de la pregunta'\n              : ''\n          }`\n        ",modifiers:{"top":true,"noninteractive":true,"v-danger":true}}],staticClass:"mt-0 input-number",class:{
          'score-input-warning':
            _vm.maxScore > _vm.Question.max_score &&
            _vm.matching_question_score.score > 0,
        },attrs:{"type":"number","size":"sm"},on:{"input":function($event){return _vm.PatchScore(_vm.matching_question_score)}},model:{value:(_vm.matching_question_score.score),callback:function ($$v) {_vm.$set(_vm.matching_question_score, "score", $$v)},expression:"matching_question_score.score"}}):[_c('div',{staticClass:"border-div ml-2 mr-3 pl-1 pr-1 noprint"},[_vm._v(" "+_vm._s(_vm.matching_question_score.score)+" ")])]],2):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }